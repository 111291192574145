* {
  background-color: #0c1a1a;
  /* background-color: #000814; */

  /* background-color: #283448; */
  /* color: rgb(228, 209, 101); */
  /* background-color: #003566; */
  /* border: 1px solid red; */
}
/* @import url("https://fonts.googleapis.com/css2?family=Chelsea+Market&family=Fira+Sans:wght@400;500&family=Irish+Grover&family=Lobster&family=Oswald&family=Oxygen&family=Poppins:wght@300&family=Readex+Pro&family=Roboto&family=Roboto+Mono&family=Rowdies:wght@300&family=Source+Sans+Pro&family=Teko:wght@300&family=Zen+Old+Mincho&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Chelsea+Market&family=Fira+Sans:wght@400;500&family=Irish+Grover&family=Lobster&family=Oswald&family=Oxygen&family=Poppins:wght@300&family=Readex+Pro&family=Roboto&family=Roboto+Mono&family=Rowdies:wght@300&family=Rubik&family=Source+Sans+Pro&family=Teko:wght@300&family=Zen+Old+Mincho&display=swap");

.App {
  display: flex;
  flex-direction: row;
  height: 100%;
}
