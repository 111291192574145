.landing_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
}
html {
  scroll-behavior: smooth;
}
.landing_min {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 80vh;
  justify-content: space-between;
}

.landing_title {
  color: #e5e5e5;

  font-size: 2.26rem;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Roboto";
  margin-top: 50px;
}
.hello_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.landing_title h2 {
  font-size: 2.5rem;
  /* text-shadow: 0 0 7px rgba(255, 255, 255, 0.3),
    0 0 3px rgba(255, 255, 255, 0.3); */
  color: #6acfc7;
  margin-right: 5px;
}
.landing_html {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: capitalize;
  line-height: 25px;
}
.landing_html p {
  margin: 0;
}
.par_element1,
.par_element2 {
  color: #fff;
  font-size: 0.9rem;
  width: 50%;
}
.par_element2 {
  display: flex;
  justify-content: flex-end;
}
.par_description {
  color: #fff;
  width: 50%;
  font-size: 1.3rem;
  font-weight: 50;
  display: flex;
  justify-content: center;
  text-align: center;
}

.landing_animation {
  height: 50px;
  overflow: hidden;
}

.landing_animation > div > div {
  width: 100%;
  padding: 0.25rem 0.75rem;
  height: 2.81rem;
  margin-bottom: 2.81rem;
  display: inline-block;
}

.landing_animation div:first-child {
  animation: text-animation 8s infinite;
}

.first div {
  /* background-color: #6acfc7; */
  color: #6acfc7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.second div {
  /* background-color: #6acfc7; */
  color: #6acfc7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.third div {
  /* background-color: #6acfc7; */
  color: #6acfc7;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes text-animation {
  0% {
    margin-top: 0;
  }
  10% {
    margin-top: 0;
  }
  20% {
    margin-top: -5.62rem;
  }
  30% {
    margin-top: -5.62rem;
  }
  40% {
    margin-top: -11.24rem;
  }
  60% {
    margin-top: -11.24rem;
  }
  70% {
    margin-top: -5.62rem;
  }
  80% {
    margin-top: -5.62rem;
  }
  90% {
    margin-top: 0;
  }
  100% {
    margin-top: 0;
  }
}

.landing_contact_button {
  display: flex;
  justify-content: center;
}

.landing_contact {
  --c: #6acfc7;
  color: var(--c);
  font-size: 16px;
  border: 0.3em solid var(--c);
  border-radius: 0.5em;
  width: 12em;
  height: 3em;
  text-transform: uppercase;
  font-weight: bold;
  font-family: sans-serif;
  letter-spacing: 0.1em;
  text-align: center;
  line-height: 3em;
  position: relative;
  overflow: hidden;
  z-index: 1;
  transition: 0.5s;
  margin: 1em;
}

.landing_contact span {
  position: absolute;
  width: 25%;
  height: 100%;
  background-color: var(--c);
  transform: translateY(150%);
  border-radius: 50%;
  left: calc((var(--n) - 1) * 25%);
  transition: 0.5s;
  transition-delay: calc((var(--n) - 1) * 0.1s);
  z-index: -1;
  border: none;
}

.landing_contact:hover {
  color: black;
}

.landing_contact:hover span {
  transform: translateY(0) scale(2);
}
.landing_contact span:nth-child(1) {
  --n: 1;
}

.landing_contact span:nth-child(2) {
  --n: 2;
}

.landing_contact span:nth-child(3) {
  --n: 3;
}
.landing_contact span:nth-child(4) {
  --n: 4;
}

.landing_scroll {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  min-height: 20vh;
}

.landing_scroll p {
  display: flex;
  justify-content: center;
  color: #fff;
  width: 25%;
  height: 25%;

  -moz-animation: landing_scroll 2s infinite;
  -webkit-animation: landing_scroll 2s infinite;
  animation: landing_scroll 2s infinite;
}

@keyframes landing_scroll {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@media (max-width: 1300px) {
  .landing_min {
    min-height: 70vh;
  }
}
@media (max-width: 850px) {
  .landing_title {
    font-size: 2rem;
    margin-top: 20px;
  }
}

@media (max-width: 650px) {
  .landing_title h2 {
    font-size: 2rem;
  }
  .landing_title {
    font-size: 1.5rem;
  }
}

@media (max-width: 500px) {
  .landing_title h2 {
    font-size: 1.6rem;
  }
  .landing_title {
    font-size: 1.3rem;
  }
}

@media (max-width: 420px) {
  .landing_title h2 {
    font-size: 1.3rem;
  }
  .landing_title {
    font-size: 1rem;
  }
  .landing_animation {
    height: 30px;
  }
  .landing_html p {
    font-size: 1rem;
    width: 90%;
  }

  .landing_contact {
    width: 11em;
    height: 2.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8rem;
  }
}
