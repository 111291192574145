.about_container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
}
.about_title h1 {
  font-weight: 200;
}
.about_title {
  display: flex;
  width: 100%;
  margin-bottom: 80px;
  font-size: 1.8rem;
  text-transform: uppercase;

  animation: 1.5s fadeInUp;
}

.about_title h1 {
  display: flex;
  justify-content: center;
  color: #ffffff;
  width: 100%;
}
.about_description_container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  align-items: center;
  width: 80%;
  border-top: 1px dashed #ffffff;
  border-bottom: 1px dashed #ffffff;
  line-height: 25px;
}
.about_inside_par {
  color: #ffffff;
  margin: 0;
  width: 90%;
  font-size: 1.2rem;
  font-family: "Rubik";
}
.about_inside_par p {
  margin: 10px;
}

.about_par_element1,
.about_par_element2 {
  width: 90%;
  display: flex;
  font-size: 1rem;
  color: #ffffff;
  margin: 0;
}

.about_link {
  text-decoration: none;

  font-size: 1.5rem;
}
.about_link h5 {
  color: #6acfc7;
  padding: 8px;
  border-radius: 5px;
  transition: 0.9s ease;
}
.about_link h5:hover {
  background-color: #6acfc7;
  color: black;
}

.about_img_div {
  width: 40%;
  display: flex;
  justify-content: center;
  margin: auto;
  box-shadow: 0 4px 8px 0 #6acfc7, 0 4px 8px 0 #6acfc7;
}
.about_img_div img {
  object-fit: contain;
  width: 85%;
}

@media (max-width: 800px) {
  .about_img_div {
    width: 60%;
  }
  .about_img_div img {
    object-fit: contain;
  }
}

@media (max-width: 420px) {
  .about_title {
    font-size: 1rem;
  }
  .about_par_element1,
  .about_par_element2 {
    font-size: 0.9rem;
  }
  .about_inside_par p {
    font-size: 1rem;
  }
  .about_link h5 {
    font-size: 1rem;
  }
}
