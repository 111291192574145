.loading_main_container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
}
.loading_inside_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loading_main_container h4 {
  display: flex;
  justify-content: center;
  width: 100%;
  color: white;
  font-family: "Rubik";
  font-weight: 100;
  font-size: 2rem;
  margin: 10px;
}

.loading_grey {
  background-color: #fff;
  margin: 0;
  width: 60%;
  border-radius: 10px;
  height: 5px;
}

.loading_golden {
  background-color: #6acfc7;
  border-radius: 10px;
  width: 100%;
  height: 5px;
  animation: 1s ease-out 0s 1 loading;
}

@keyframes loading {
  0% {
    width: 0;
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 1300px) {
  .loading_main_container {
    flex-direction: column;
    height: 100vh;
    width: 100%;
    justify-content: center;
  }
}
