.work_main_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.work_container {
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  margin-bottom: 2rem;
}

.work_title {
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
  color: #ffffff;
  background-color: transparent;
}

.work_title h1 {
  background-color: transparent;
  font-weight: 200;
}

.work_company {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  background-color: transparent;
}

.img_container {
  width: 40%;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.img_container:hover {
  transform: translateY(-5px);
}

.img_container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  transition: transform 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .work_container {
    width: 90%;
  }
  .img_container {
    width: 80%;
  }
}
